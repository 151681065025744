.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.NavBar-Logout {
  margin: 10px;
}

.StaticTimePickerLandscape-TimePicker {
  margin: 10px;
}

.VidPage {
  margin: 10px;
}

.DevicesList {
  display: flex;
}

.LandingPage {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}

.Layout-Content {
  display: flex;
  align-items: flex-start;
  margin: 20px;
  /* align-items: center; */
  /* padding: 20px; */
  /* justify-content: ; */
  /* justify-content: left; */
  /* align-items: center; */
  justify-content: space-between;
  /* padding-right: 300px; */
}
.Layout-Outlet {
  justify-content: space-between;
  padding-left: 70px;
}
.Layout-Content-Media {
  /* margin-left: 0; */
  /* display: flex; */
  flex-grow: 1;
  justify-content: space-between;
}

.DeviceSelect {
  display: flex;
  align-items: center;
}

.DataStreamSelect {
  display: flex;
  align-items: center;
}

.DeviceSelect-ConnectionStatus {
  margin: 10px;
}

.DeviceSelect-Form {
  margin: 10px;
}

.DataStreamSelect-Form {
  margin: 10px;
}

.BasicDatePicker-DatePicker {
  margin: 10px;
}

.Chart {
  margin: 10px;
}

.AuthPage {
  display: flex;
  justify-content: center;
  padding-top: 120px;
}

/* .Layout-Content-Media{
  display: flex;
  /* padding: 20px; */
/* justify-content: space-between; */
/* align-items: center; */
/* margin-left: 20px; */
/* }  */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.Layout-TopNav {
  display: flex;
  padding: 10px;
  /* background-color: grey; */
  justify-content: space-between;
}
/* .Layout
.App > Layou {
} */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Layout-Right {
  padding-left: 100px;
}

.SettingsSelect {
  display: flex;
}

.SettingsSelect-Visualization {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
